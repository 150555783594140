@import url(https://fonts.googleapis.com/css2?family=Comfortaa&display=swap);
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    margin: 0;
    /* background: linear-gradient(90deg, #4b6cb7, #182848); */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace
}

/* Ensure input fields and icons align properly */
.input-group-text {
  display: flex; /* Ensures the content within aligns properly */
  align-items: center; /* Centers the content vertically */
  justify-content: center; /* Centers the content horizontally (useful if you adjust the width or padding) */
}

/* Adjusts the size and alignment of icons within the input group */
.form-icon {
  font-size: 1rem; /* Adjusts icon size to fit better with the text fields */
}

/* Custom styling for the Material Icons if used outside the input groups */
.material-icons {
  font-size: 24px; /* Adjust size as needed */
  vertical-align: middle; /* Aligns icon vertically in the middle for consistency */
}

/* Styles for the form elements */
.login-form button[type="submit"] {
  width: 100%; /* Makes the submit button width consistent with the form fields */
  margin-top: 1rem; /* Adds some space above the submit button */
  background-color: #007bff; /* Example button color, adjust as needed */
  color: white; /* Text color for the button */
  border-radius: 0.25rem; /* Rounds the corners of the button */
  border: none; /* Removes the default border */
  padding: 0.5rem; /* Adds padding inside the button for better sizing */
}

.login-form button[type="submit"]:hover {
  background-color: #0056b3; /* Darker shade for hover effect, adjust as needed */
}

/* Ensures the form itself is centered and looks good on all screen sizes */
.login {
  display: flex;
  justify-content: center; /* Centers the form horizontally */
  align-items: center; /* Centers the form vertically */
  min-height: 100vh; /* Ensures the form is vertically centered in the viewport */
}

.form {
  width: 100%; /* Adjust the width as necessary, potentially with a max-width for larger screens */
  max-width: 400px; /* Maximum width of the form to ensure it doesn't stretch too much on larger screens */
}
