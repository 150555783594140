*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Custom Properties, update these for your own design */

:root {
    --ff-primary: 'Source Sans Pro', sans-serif;
    --ff-secondary: 'Source Code Pro', monospace;

    --fw-reg: 300;
    --fw-bold: 900;

    --clr-light: #fff;
    --clr-dark: #303030;
    /* --clr-accent: #16e0bd; */
    --clr-accent: #ADD8E6;
    /* --clr-accent: #FFFF00; */
    /* --clr-accent: #FFC300; */

    --fs-h1: 3rem;
    --fs-h2: 2.25rem;
    --fs-h3: 1.25rem;
    --fs-body: 1rem;

    --bs: 0.25em 0.25em 0.75em rgba(0,0,0,.25),
          0.125em 0.125em 0.25em rgba(0,0,0,.15);
}

@media (min-width: 800px) {
    :root {
        --fs-h1: 4.5rem;
        --fs-h2: 3.75rem;
        --fs-h3: 1.5rem;
        --fs-body: 1.125rem;
    }
}

/* General styles */

/* endable this to add smooth scrolling */
/* html {
    scroll-behavior: smooth;
} */


body {
    background: var(--clr-light);
    color: var(--clr-dark);
    margin: 0;
    font-family: var(--ff-primary);
    font-size: var(--fs-body);
    line-height: 1.6;
}

section {
    padding: 5em 2em;
}

img {
    display: block;
    max-width: 100%;
}

strong { font-weight: var(--fw-bold) }

:focus {
    outline: 3px solid var(--clr-accent);
    outline-offset: 3px;
}

/* Buttons */

.btn {
    display: inline-block;
    padding: .5em 2.5em;
    background: var(--clr-accent);
    color: var(--clr-dark);
    text-decoration: none;
    cursor: pointer;
    font-size: .8rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: var(--fw-bold);
    transition: transform 200ms ease-in-out;
}

.btn:hover {
    transform: scale(1.1);
}

/* Typography */

h1,
h2,
h3 {
    line-height: 1;
    margin: 0;
}

h1 { font-size: var(--fs-h1) }
h2 { font-size: var(--fs-h2) }
h3 { font-size: var(--fs-h3) }


.section__title {
    margin-bottom: .25em;
}

.section__title--intro {
    font-weight: var(--fw-reg);
}

.section__title--intro strong {
    display: block;
}

.section__subtitle {
    margin: 0;
    font-size: var(--fs-h3);
}

.section__subtitle--intro,
.section__subtitle--about {
    background: var(--clr-accent);
    padding: .25em 1em;
    font-family: var(--ff-secondary);
    margin-bottom: 1em;
}

.section__subtitle--work {
    color: var(--clr-accent);
    font-weight: var(--fw-bold);
    margin-bottom: 2em;
}

/* header */

header {
    display: flex;
    justify-content: space-between;
    padding: 1em;
}

.logo {
    max-width: 100px;
}

.nav {
    position: fixed;
    background: var(--clr-dark);
    color: var(--clr-light);
    /* TODO */
    height:40vh;
    top: 0;
    bottom: 40%;
    left: 60%;
    right: 0;
    z-index: 100;

    transform: translateX(100%);
    transition: transform 250ms cubic-bezier(.5, 0, .5, 1);
}

.nav__list {
    list-style: none;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    padding: 0;
}

.nav__link {
    color: inherit;
    font-weight: var(--fw-bold);
    font-size: var(--fs-h2);
    text-decoration: none;
}

.nav__link:hover {
    color: var(--clr-accent);
}

.nav-toggle {
    padding: .5em;
    background: transparent;
    border: 0;
    cursor: pointer;
    position: absolute;
    right: 1em;
    top: 1em;
    z-index: 1000;
}

.nav-open .nav {
    transform: translateX(0);
}

.nav-open .nav-toggle {
    position: fixed;
}

.nav-open .hamburger {
    transform: rotate(.625turn);
}

.nav-open .hamburger::before {
    transform: rotate(90deg) translateX(-6px);
}

.nav-open .hamburger::after {
    opacity: 0;
}

.hamburger {
    display: block;
    position: relative;
}

.hamburger,
.hamburger::before,
.hamburger::after {
    background: var(--clr-accent);
    width: 2em;
    height: 3px;
    border-radius: 1em;
    transition: transform 250ms ease-in-out;
}


.hamburger::before,
.hamburger::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
}

.hamburger::before { top: 6px; }
.hamburger::after { bottom: 6px; }


/*  Intro section  */

.intro {
    position: relative;
}

.intro__img {
    box-shadow: var(--bs);
}

.section__subtitle--intro {
    display: inline-block;
}

@media (min-width: 600px) {
    .intro {
        display: grid;
        width: min-content;
        margin: 0 auto;
        grid-column-gap: 1em;
        grid-template-areas:
            "img title"
            "img subtitle";
        grid-template-columns: min-content max-content;
    }

    .intro__img {
      grid-area: img;
      min-width: 250px;
      position: relative;
      z-index: 2;
    }

    .section__subtitle--intro {
      align-self: start;
      grid-column: -1 / 1;
      grid-row: 2;
      text-align: right;
      position: relative;
      left: -1.5em;
      width: calc(100% + 1.5em);
    }
}



/*  My services section  */

.my-services {
    background-color: var(--clr-dark);
    /* background-image: url(../img/services-bg.webp); */
    /* background-image: url(/img/services-bg-07.jpg); */
    background-size: cover;
    /* background-blend-mode: multiply; */
    color: var(--clr-light);
    text-align: center;
}

.section__title--services {
    color: var(--clr-accent);
    position: relative;
}

.section__title--services::after {
  content: '';
  display: block;
  width: 2em;
  height: 1px;
  margin: 0.5em auto 1em;
  background: var(--clr-light);
  opacity: 0.25;
}

.services {
  margin-bottom: 4em;
}

.service {
  text-align: left;
  max-width: 500px;
  margin: 0 auto;
}

.service-header {
  color: var(--clr-accent);
}

@media (min-width: 800px) {
    .services {
        display: flex;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }

    .service + .service {
        margin-left: 2em;
    }
}


.about-me {
    max-width: 1000px;
    margin: 0 auto;
}

.about-me__img {
    box-shadow: var(--bs);
}

@media (min-width: 600px) {
    .about-me {
        display: grid;
        grid-template-columns: 1fr 200px;
        grid-template-areas:
            "title img"
            "subtitle img"
            "text img";
        grid-column-gap: 2em;
    }

    .section__title--about {
        grid-area: title;
    }

    .section__subtitle--about {
        grid-column: 1 / -1;
        grid-row: 2;
        position: relative;
        left: -1em;
        width: calc(100% + 2em);
        padding-left: 1em;
        padding-right: calc(200px + 4em);
    }

    .about-me__img {
        grid-area: img;
        position: relative;
        z-index: 2;
    }
}

/* My Work */

.my-work {
    background-color: var(--clr-dark);
    color: var(--clr-light);
    text-align: center;
}

.portfolio {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.portfolio__item {
    background: var(--clr-accent);
    overflow: hidden;
}

.portfolio__img {
    transition:
        transform 750ms cubic-bezier(.5, 0, .5, 1),
        opacity 250ms linear;
}

.portfolio__item:focus {
    position: relative;
    z-index: 2;
}

.portfolio__img:hover,
.portfolio__item:focus .portfolio__img {
    transform: scale(1.2);
    opacity: .5;
}


/* footer */

.footer {
    background: #111;
    color: var(--clr-accent);
    text-align: center;
    padding: 2.5em 0;
    font-size: var(--fs-h3);

}

.footer a {
    color: inherit;
    text-decoration: none;
}

.footer__link {
    font-weight: var(--fw-bold);
}

.footer__link:hover,
.social-list__link:hover {
    opacity: .7;
}

.footer__link:hover {
    text-decoration: underline;
}

.social-list {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 2em 0 0;
    padding: 0;
}

.social-list__item {
    margin: 0 .5em;
}

.social-list__link {
    padding: .5em;
}


/* Individual portfolio item styles */

.portfolio-item-individual {
    padding: 0 2em 2em;
    max-width: 1000px;
    margin: 0 auto;
}

.portfolio-item-individual p {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}


/* hamburger */
.navigation {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 120px;
  height: 60px;
  background: #fff;
  box-shadow: 0 25px 35px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  transition: height 0.5s, width 0.5s;
  transition-delay: 0s, 0.75s;
  overflow: hidden;
  z-index: 100;
}

.navigation.active {
  width: 300px;
  height: 350px;
  transition: width 0.5s, height 0.5s;
  transition-delay: 0s, 0.75s;
}

.navigation .userBx {
  position: relative;
  width: 60px;
  height: 60px;
  background: #fff;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
  transition-delay: 0.5s;
}

.navigation.active .userBx {
  width: calc(100% - 60px);
  transition-delay: 0s;
}

.navigation .userBx .username {
  white-space: nowrap;
  /* color: #555; */
  color: var(--clr-dark);
  /* color: var(--clr-accent); */
  font-size: 1.1em;
}

.navigation .userBx .imgBx {
  position: relative;
  min-width: 60px;
  height: 60px;
  overflow: hidden;
  background: #000;
  border-radius: 50%;
  border: 10px solid #fff;
}

.navigation .userBx .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Hamburguer toggle menu */

.navigation .menuToggle {
  position: relative;
  width: 60px;
  height: 60px;
  color: var(--clr-accent);
  background: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation .menuToggle::before {
  content: "";
  position: absolute;
  width: 32px;
  height: 2px;
  background: #555;
  transform: translateY(-10px);
  box-shadow: 0 10px #555;
  transition: 0.5s;
}

.navigation .menuToggle::after {
  content: "";
  position: absolute;
  width: 32px;
  height: 2px;
  background: #555;
  transform: translateY(10px);
  transition: 0.5s;
}

.navigation.active .menuToggle::before {
  transform: translateY(0px) rotate(45deg);
  box-shadow: 0 0 #555;
}

.navigation.active .menuToggle::after {
  transform: translateY(0px) rotate(-45deg);
}

.menu {
  position: absolute;
  width: 100%;
  height: calc(100% - 60px);
  margin-top: 60px;
  padding: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.menu li {
  list-style: none;
}

.menu li a {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
  font-size: 1em;
  text-decoration: none;
  /* color: #555; */
}

.menu li a:hover {
  /* color: #4e65ff; */
  color: var(--clr-accent)
}

.menu li a ion-icon {
  font-size: 1.5em;
}
/* hamburger */
